html {
  font-size: 10px;
}

body {
  background-color: #F2F3F8;
  color: #132A3D;
  font: 300 1.8rem/1.5 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

a {
  color: #132A3D;
}

button a {
  text-decoration: none;
}

nav {
  padding-right: 3rem;
}

nav ul {
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

nav li {
  display: block;
  margin: 2rem 3rem;
  width: auto;
}

nav a {
  display: block;
  font-weight: 500;
  font-size: 1.5rem;
  padding: 1.2rem 0;
  text-transform: uppercase;
  text-decoration: none;
  transition: color 300ms;
}

nav a:hover {
  color: #ee0417;
}

.primary-button {
  background: #132A3D;
  border-radius: 4rem;
  box-shadow: 3px 3px 0 transparent;
  color: #F5F5F5;
  padding-left: 3rem;
  padding-right: 3rem;
}

.primary-button:hover {
  background: #EE0417;
  box-shadow: 0 0 0 #BECDDA;
  color: #F5F5F5;
  transform: translate(2px, 2px);
  transition: background 150ms, transform 150ms;
}

.hero {
  padding: 4.0rem 0 0 0;
  text-align: center;
  background-image: url(../images/backgrounds/bg-header3.png);
  background-repeat: no-repeat;;
}

.title {
  font-size: 7rem;
  font-style: italic;
}

.subtitle {
  text-align: center;
  font-size: 3.0rem;
  font-style: italic;
  font-weight: 100;
  margin: -1.5rem auto 2rem auto;
}

.secondary {
  font-size: 2.0rem;
  margin: 5rem auto;
  width: 50%;
}

.videoWrapper {
  position: center;
  margin: 5rem auto;
  width: 60%;
  height: auto;
}
.videoWrapper iframe {
  position: center;
}

.games {
  background-color: #FFF; 
  /*margin-top: 5rem;*/
  /*padding: 5.0rem 0;*/
}

.game {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*margin-bottom: 5.0rem;*/
  padding: 5rem 0;
  text-align: center;
}

.game h2 {
  color: #FFF;
  font-size: 3.2rem;
  font-style: italic;
  letter-spacing: 0.1rem;
  text-shadow: 2px 2px 0 transparent;
  text-transform: uppercase;
}

.kongregate h2 {
  color: #FFF;
  text-shadow: 2px 2px 0 transparent;
}

/* Game Backgrounds */
.bit-heroes {
  background-image: url(../images/backgrounds/bg-bit-heroes.jpg);
}

.surviv-io {
  background-image: url(../images/backgrounds/bg-surviv-io.png);
}

.kongregate {
  background-image: url(../images/backgrounds/bg-kongregate2.png);
  background-position: bottom;
}

.game-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1rem auto 0.5rem;
  text-align: left;
  width: 112.5rem;
}

.item {
  background-color: #F5F5F5;
  border: 0px solid transparent; 
  border-radius: 2.5rem;
  box-shadow: 2px 2px 0 #transparent;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 2.5rem;
  position: relative;
  width: 32.5rem;
}

.item-image {
  border-radius: 2.2rem 2.2rem 0 0;
  display: flex;
  height: 27.3rem;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.item-image img {
  border-radius: 2.2rem 2.2rem 0 0;
  display: block;
}

.item-tag {
  background-color: #fea000;
  border-radius: 2rem;
  padding: 5px 10px;
  margin-left: 10px;
  bottom: 45px;
  position: relative;
}

.item-details {
  padding: 1.5rem 2.5rem 9rem;
}

.item-desc {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.item-rarity {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0.5rem 0;
  text-transform: uppercase;
  position: absolute;
  left: 10rem;
  bottom: 6rem;
}

.item-owned {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0.5rem 0.5rem;
  text-transform: uppercase;
  position: absolute;
  left: 10rem;
  bottom: 8rem;
}


.item-image#kongregate {
  background-color: #FAE351;
}

.item-image#bit-heroes {
  background-color: #5F91D0;
}

.item-image#surviv-io {
  background-color: #CCB55A;
}
.price-usd {
  position: absolute;
  bottom: 0.2rem;
  left: 13rem;
  color: #0068ae;
  font-size: smaller;
}

.buy {
  background: transparent;
  border: 0;
  bottom: 2.5rem;
  position: absolute;
  width: 26.5rem;
}

.buy-button {
  display: block;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  padding: 1rem 0;
  text-transform: uppercase;
  width: 100%;
}

.buy-button:hover {
  background: #2AB085;
}

@media only screen and (max-width: 1200px) {
  .game-items {
    width: 84rem;
  }
  .item {
    margin: 1.5rem 1.5rem;
    width: 25rem;
  }
  .item-image {
    height: 21rem;
  }
  .item-details {
    padding: 1.5rem 2rem 9rem;
  }
  .buy-button {
    width: calc(100% - 6rem);
  }
}

@media only screen and (max-width: 900px) {
  nav {
    padding-right: 0;
  }
  nav ul {
    justify-content: space-around;
  }
  nav li {
    margin: 1rem;
  }
  .secondary {
    width: 80%;
  }
  .title {
    font-size: 6rem;
  }
  .subtitle {
    font-size: 2rem;
  }
  .game-items {
    flex-direction: column;
    width: 80%;
  }
  .item {
    flex-direction: row;
    min-height: 25rem;
    width: auto;
  }
  .item-image {
    border-bottom: 0;
    border-radius: 2.2rem 0 0 2.2rem;
    height: auto;
    width: 40%;
  }
  .item-image img {
    border-radius: 2.2rem 0 0 2.2rem;
    height: auto;
  }
  .item-details {
    width: 60%;
  }
  .item-desc {
    font-size: 1.6rem;
  }
  .buy-button {
    bottom: 2.5rem;
  }

  .videoWrapper iframe{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 480px) {
  nav {
    padding: 0 1rem;
  }
  nav li {
    margin: 1rem 0.5rem;
  }
  nav a {
    font-size: 1.4rem;
    padding: 1rem 0;
  }
  nav .primary-button {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
  .secondary {
    font-size: 1.6rem;
  }
  .hero {
    margin: 4rem 0 5rem;
  }
  .title {
    font-size: 4rem;
  }
  .subtitle {
    font-size: 1.6rem;
  }
  .game {
    padding: 2rem 0;
  }
  .game h2 {
    font-size: 2.6rem;
  }
  .game-items {
    width: 90%;
  }
  .item {
    flex-direction: column;
    max-width: 32.5rem;
  }
  .item-image {
    border-radius: 2.2rem 2.2rem 0 0;
    width: 100%;
  }
  .item-image img {
    border-radius: 2.2rem 2.2rem 0 0;
    height: auto;
  }
  .item-details {
    width: auto;
  }
  .buy {
    width: calc(100% - 4rem);
  }
  .buy-button {
    bottom: 2.5rem;
    width: 100%;
  }
}

@media only screen and (max-width: 320px) {
  nav .primary-button {
    background: transparent;
    border-radius: 0;
    box-shadow: 0 0 0 transparent;
    color: inherit;
    padding-left: 0;
    padding-right: 0;
  }
  }

}
