.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

amplify-authenticator {
  --amplify-background-color: #EAEAEA !important;
  --amplify-font-family: 'Rubik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --amplify-font-size: 1.8rem/1.5;
  --container-height: 0;
  --container-display: block;
  --width: 100%;
  --box-shadow:none;
}

:root {
  --amplify-text-xxs: 1.35rem;
  --amplify-text-xs: 1.458rem;
  --amplify-text-sm: 1.575rem;
  --amplify-text-md: 1.8rem;
  --amplify-text-md-sub: 2.07rem;
  --amplify-text-lg: 2.7rem;
  --amplify-text-xl: 3.6rem;
  --amplify-text-xxl: 4.5rem;
}

.purchase-error {
  color: #f03;
}

.buy-more-button {
  padding: 5px 10px !important;
  text-align: center !important;
  display: inline !important;
  vertical-align: sub !important;
  font-size: 12px;
}

.centered-main-div {
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 7.0rem;
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 100% !important;
    height: 100% !important;
}
