.modal {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.5);
}

.stackable-details {
  align-items: center;
}

.stackable-details img {
  width: 20%;
  height: 20%;
  position: relative;
  left: 22rem;
}

.purchase-review {
  background-color: #EAEAEA;
  border: 3px solid #132A3D;
  border-radius: 2.5rem;
  box-shadow: 2px 2px 0 #132A3D;
  margin: auto;
  padding: 4rem 2.5rem;
  position: relative;
  text-align: center;
  width: 60rem;
}

.purchase-review h1 {
  line-height: 1.4;
}

.purchase-review .item {
  flex-direction: row;
  margin: 3rem auto;
  min-height: auto;
  width: calc(100% - 5rem);
}

.purchase-review .item-image {
  border-bottom: 0;
  border-radius: 2.2rem 0 0 2.2rem;
  height: auto;
  width: 40%;
}

.purchase-review .item-image img {
  border-radius: 2.2rem 0 0 2.2rem;
  height: auto;
}

.purchase-review .item-details {
  padding: 1.5rem 2rem;
  text-align: left;
  width: 60%;
}

.purchase-review .item-owned {
  left: 25rem
}

.purchase-review .item-rarity {
 left: 25rem;
}

.purchase-review .price-usd {
  position: absolute;
  bottom: -18rem;
  left: 22rem;
  color: #0068ae;
  font-size: smaller;
}

.purchase-review .helper {
  margin: 2rem 0;
}

.purchase-review .wallet-balance {
  font-weight: 500;
  text-transform: uppercase;
}

.purchase-review .buy {
  bottom: auto;
  font-size: 1.8rem;
  position: relative;
  width: 70%;
}

.purchase-review .buy-button {
  font-size: 1.6rem;
  padding: 1.2rem 0;
  width: 100%;
}

.purchase-review .ext-button::after {
  content: url(../images/icons/external-link.svg);
  fill: #F5F5F5;
  padding-left: 1rem;
  position: relative;
  top: 0.2rem;
}

.purchase-review .comp-button {
  color: #1dc38e;
}

.purchase-review .comp-button:hover {
  background: #2AB085;
  color: #F5F5F5;
  transition: background 150ms, color 150ms;
}

.close {
  color: #132A3D;
  float: right;
  font-size: 28px;
  font-weight: bold;
  position: absolute;
  top: 1rem;
  right: 2rem;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .purchase-review {
    width: 90%;
  }
  .purchase-review .item {
    flex-direction: column;
    max-width: none;
    width: 90%;
  }
  .purchase-review .item-image {
    border-radius: 2.2rem 2.2rem 0 0;
    width: auto;
  }
  .purchase-review .item-image img {
    border-radius: 2.2rem 2.2rem 0 0;
    height: auto;
  }
  .purchase-review .item-details {
    width: auto;
  }
  .purchase-review .buy {
    width: calc(100% - 2rem);
  }
}
